.cursor {
  position: fixed;
  width: 12px;
  height: 12px;
  background-color: orange;
  border-radius: 50%;
  pointer-events: none;
  transition: left 0.2s linear, top 0.2s linear;
  z-index: 1000;
}
@media only screen and (max-width: 768px) {
  .cursor {
    display: none;
  }
}
