.hero-section .hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  text-align: left;
  padding-top: 40px;
  padding-bottom: 200px;
}
.hero-section .hero-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--white);
}
.hero-title .company-name {
  color: #31ff7c;
}
.hero-section .hero-para {
  font-size: 18px;
  color: var(--white);
  line-height: 30px;
}

.hero-section button {
  width: 290px;
  height: 80px;
  border: 2px solid #ffffff;
  background-color: transparent;
  color: var(--white);
  border-radius: 50px;
  font-size: 20px;
}

.hero-section button:hover {
  background-color: var(--white);
  color: var(--primary-green);
}

.content-right img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border-style: none;
  object-fit: cover;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-section .hero-title {
    font-size: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-section .hero-title {
    font-size: 30px;
  }
  .hero-section .hero-para {
    font-size: 14px;
  }
  .hero-section button {
    width: 240px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-section .hero-content {
    height: 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .content-left {
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .hero-section .hero-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 479px) {
}
